import About from './About/About'
import './App.css'

function App() {
	return (
		<div className="App">
			<About />
		</div>
	)
}

export default App
